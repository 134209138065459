<template>
  <div>
    <h3>Contacts</h3>
    <div class="card" v-if="! loading">
      <div class="card-body">
        <form @submit.prevent="submit">
          <div class="row mb-3">
            <div class="col-md-2 text-end">
              <label>Phone Number</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                placeholder="Phone Number"
                v-model="data.phone_number"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-end">
              <label>Email</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                placeholder="Email"
                v-model="data.email"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-end">
              <label>Location</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                placeholder="Location"
                v-model="data.location"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-end">
              <label>Twitter</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                placeholder="Twitter"
                v-model="data.twitter"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-end">
              <label>Facebook</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                placeholder="Facebook"
                v-model="data.facebook"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-end">
              <label>Youtube</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                placeholder="Youtube"
                v-model="data.youtube"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-end">
              <label>Tiktok</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                placeholder="Tiktok"
                v-model="data.tiktok"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-end">
              <label>Instagram</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                placeholder="Instagram"
                v-model="data.instagram"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-end">
              <label>Linkedin</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                placeholder="Linkedin"
                v-model="data.linkedin"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-end">
              <label>Pinterest</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                placeholder="Pinterest"
                v-model="data.pinterest"
                class="form-control"
              />
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-10">
              <button class="btn btn-primary">
                <i class="lni lni-save me-2"></i>Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      data: {},
      loading: true
    };
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get("/api/v1/admin/content/contact").then((response) => {
        this.data = response.data.content;
        this.loading = false
      });
    },

    submit() {
      let data = {
        content: this.data
      }

      this.$axios.put('/api/v1/admin/content/contact', data).then(() => {
        this.fetch()
      })
    }
  }
};
</script>
